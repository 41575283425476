<template>
    <div>
        <a-form :form="form">
            <a-row type="flex" :gutter="24">
                <a-col :span="24" :lg="24" class="mb-24">
                    <a-card :bordered="false" class="header-solid h-full"
                        :bodyStyle="{ paddingTop: 0, paddingBottom: '40px' }">
                        <template #title>
                            <h5 class="mb-0">User details</h5>
                        </template>
                        <a-row :gutter="[24]">
                            <a-col :span="6">
                                <a-form-item class="mb-10" label="Name" :colon="false">
                                    <a-input v-model="userData.data.name" :disabled="true" />
                                </a-form-item>
                            </a-col>

                            <a-col :span="6">
                                <a-form-item class="mb-10" label="Email" :colon="false">
                                    <a-input v-model="userData.data.email" :disabled="true" />
                                </a-form-item>
                            </a-col>

                            <a-col :span="6">
                                <a-form-item class="mb-10" label="Slug" :colon="false">
                                    <a-input v-model="userData.data.slug" :disabled="true" />
                                </a-form-item>
                            </a-col>

                            <a-form-item class="mb-10" label="Blocked" :colon="false">
                                <a-switch v-model="userData.data.is_blocked" @change="handleBlockedChange" />
                            </a-form-item>
                        </a-row>
                    </a-card>
                </a-col>
                <!-- <a-button type="primary" html-type="submit"> Submit </a-button> -->
            </a-row>
        </a-form>
    </div>
</template>

<script>
import userAPI from '../../api/user.js';

export default {
    data() {
        return {
            userData: {
                data: {
                    name: "undefined",
                    email: "undefined",
                    slug: "undefined",
                    is_blocked: false
                }
            },
            // is_active: false,
            form: this.$form.createForm(this, { name: "editUser" }),
        };
    },
    methods: {
        async getUserById(id) {
            return userAPI.getUserById(id);
        },
        async handleBlockedChange(isBlocked) {
            console.log("Blocked status changed:", isBlocked);

            if (isBlocked) {
                // If the switch is turned on, block the user
                await userAPI.blockUser(this.$route.params.id)
                    .then((response) => {
                        this.$message.success("User blocked successfully.");
                        console.log("User blocked successfully:", response);
                    })
                    .catch((error) => {
                        console.error("Error blocking user:", error);
                    });
            } else {
                // If the switch is turned off, unblock the user
                await userAPI.unblockUser(this.$route.params.id)
                    .then((response) => {
                        this.$message.success("User unblocked successfully.");
                        console.log("User unblocked successfully:", response);
                    })
                    .catch((error) => {
                        console.error("Error unblocking user:", error);
                    });
            }
        },
    },
    async mounted() {
        if (this.$route.params.id !== undefined) {
            this.userData = await this.getUserById(this.$route.params.id); // get city name by id to edit it
        }
    },
};
</script>
